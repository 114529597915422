<template>
    <section class="content-wrapper">
        <pageLoader v-show ="ploader"/>

        <!-- si connecté -->
        <div v-if="$store.state.isAuthenticated">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Bienvenue sur Beekeeper974</h4>
                            <h3 class="font-weight-light text-center">Content de vous revoir {{$store.state.firstname}} {{$store.state.lastname}}!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- si non connecté -->
        <div v-else class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Bienvenue sur Beekeeper974</h4>
                            <h6 class="font-weight-light text-center">Se connecter pour continuer.</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <input type="text" v-model="userName" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Nom d'utilisateur ou adresse mail">
                            </div>
                            <div class="form-group">
                                <input type="password" v-model="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Mot de passe">
                            </div>
                            <div class="mt-3">
                                <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white"><span>Se connecter &nbsp; <span v-show="loadingBar" class="circle-loader ml-2"></span></span></button>
                            </div>
                            <div class="my-2 d-flex justify-content-between align-items-center">
                                <div class="form-check">
                                <label class="form-check-label text-muted">
                                    <input type="checkbox" class="form-check-input">
                                    Rester connecté
                                    <i class="input-helper"></i>
                                </label>
                                </div>
                                <a href="javascript:void(0);" class="auth-link text-black">
                                    <router-link :to="{name: 'forgotPassword'}">
                                    Mot de passe oublié
                                        
                                    </router-link>
                                    </a>
                            </div>
                            <div class="text-center mt-4 font-weight-light">
                                Vous n'avez pas de compte? <router-link to="/auth-pages/register" class="text-primary">Créer</router-link>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios= require('axios').default
export default {
    name:'login',
    data () {
        return {
            userName:'',
            password:'',
            loadingBar:false,
            ploader: false
        }
    },
    methods: {
        // async handleSubmit () {
        //     this.loadingBar= true
        //     await axios.post('login',
        //     {
        //     username : this.userName, 
        //     password : this.password 
        //     },
        //     {
        //         withCredentials: true
        //     })
        //     .then(resLog => {
        //         localStorage.setItem('token', resLog.data.token)
        //         localStorage.setItem('pseudo',resLog.data.pseudo)
        //         })
        //     .catch(errLog => {
        //         console.log({errLog})
        //         axios.post('login',
        //         {
        //         username : this.userName, 
        //         password : this.password 
        //         },
        //         {
        //             withCredentials: true
        //         })
        //         .then(secondeResLog => {
        //             localStorage.setItem('token', secondeResLog.data.token)
        //             localStorage.setItem('pseudo',secondeResLog.data.pseudo)
        //             })
        //         .catch(secondErrLog => {
        //             console.log('status',secondErrLog.response.status)
        //             if(secondErrLog.response.status=="401"){
        //                 alert('Le nom d\'utilisateur ou le mot de passe comporte une erreur!')
        //                 this.loadingBar= false
        //             }
        //             else{
        //                 console.log('Nom d\'utilisateur et mot de passe validés');
        //             }
        //         }
        //         )
        //         }
        //         )
        //     }
        handleSubmit() {
            this.ploader= true
            axios.post(`client/login`,{
                username : this.userName, 
                password : this.password 
            },
            {
                withCredentials: true
            })
            .then(res => {
                this.connect_to_session(res.data.link)
            })
            .catch(err =>{
                console.log({err});
                // if(err.response.status == 401 || err.response.status == 500) {
                //     alert('Votre nom d\'utilisateur ou mot de passe est incorrect')
                //     this.ploader = false
                //     return
                // }
                console.log({err});
                alert ('Une erreur est survenue lors de la submission')
                this.ploader = false
            })
        },
        connect_to_session(link){
            axios.post(link,{withCredentials:true})
            .then(resConnectToSession => {
                this.$router.push({
                    name:'home'
                })
                console.log({resConnectToSession})
                this.ploader = false
            })
            .catch(errConnectToSession => {
                console.log('Une erreur est survenue lors de la connexion à la session',errConnectToSession)
                this.ploader = false
            })
        }
    },
    mounted() {
    }
}
</script>